import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import pumpBanner from './images/pumpBanner.png'
import Navbar from './navbar';
import line from './images/Vector.png';
import mobiles from './images/mobiles.png';
import mobile2 from './images/mobile2.png';
import laptop from './images/laptop.png';
import tick from './images/tick.png';
import stars from './images/stars.png';
import logo1 from './images/logo1.png';
import logo2 from './images/logo2.png';
import logo3 from './images/logo3.png';
import logo4 from './images/logo4.png';
import phone from './images/phone.svg';
import mail from './images/email.svg';
import quote from './images/quote.png';
import user from './images/user.png';
import modalImg from './images/modalImg.png';
import star from './images/star.png';
// import fb from './images/fb.png';

import aos from "aos";

import "aos/dist/aos.css";

import pumpifylogo from './images/2.png'
import axios from "axios";


function Pumpify() {
    const [connectModalText, setConnectModalText] = useState("");
    const [getModalText, setGetModalText] = useState("");
    const [show, setShow] = useState(false);
    const [showGetIn, setShowGetIn] = useState(false);

    const [contactForm, setContactForm] = useState({   
        name:"",
        email:"",
        phone:"",
        message:"",
    });
    const [demoForm, setDemoForm] = useState({   
        name:"",
        email:"",
        message:"",
    });
    const [newLetterMail, setnewLetterMail] = useState("");
  
    const handleClose = () => setShow(false);
    const handleShow = (text) => {
        setShow(true)
        setConnectModalText(text)
    }
    const handleCloseGet = () => setShowGetIn(false);
    const handleShowGet = (text) => {
        setShowGetIn(true)
        setGetModalText(text)
    }   
    // notification setting
    const notify = (response,types) => 
    types(response, {
       position: "bottom-right",
       autoClose: 5000,
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,
       progress: undefined,
    });

    
    const handleContactF =(e) => {
        setContactForm({...contactForm,[e.target.name]:e.target.value})
    }
    const handleDemoF =(e) => {
        setDemoForm({...demoForm,[e.target.name]:e.target.value})
    }
    const submitContactForm =(e) => {
        e.preventDefault();
        console.log(contactForm);
        var valid = /^!*(\d!*){10,}$/;
        var validM = valid.test(contactForm.phone)
        if(contactForm.phone && contactForm.phone.includes(' ') ) return notify("Spaces are not allowed in phone number",toast.error);
        if(contactForm.phone && (!validM || contactForm.phone.length < 10)) return notify("Phone must contain atleast 10 digits",toast.error);
        
        if(!contactForm.email){
            return notify("email is required",toast.error)
        }
        if(!contactForm.message){
            return notify("message is required",toast.error)
        }
        const response = axios.post('https://api.pumpify.co/contact-us-mailer', contactForm)
        .then((res)=>{
            console.log(res);
            alert("Thanks, we will reach you soon.")
            handleClose(false)
            setContactForm({   
                name:"",
                email:"",
                phone:"",
                message:"",
            });
        })
        .catch((err)=>{
            console.log(err);
            notify("Something went wrong",toast.error)
            handleClose(false)
        })
    }

    const submitDemoForm =(e) => {
        e.preventDefault();
        console.log(demoForm);
        if(!demoForm.email){
            return notify("email is required",toast.error)
        }
        if(!demoForm.message){
            return notify("message is required",toast.error)
        }
        const response = axios.post('https://api.pumpify.co/get-demo-request-mailer', demoForm)
        .then((res)=>{
            console.log(res);
            notify("Thanks, we will reach you soon.",toast.success)
            handleCloseGet(false)
            setDemoForm({   
                name:"",
                email:"",
                message:"",
            });
        })
        .catch((err)=>{
            console.log(err);
            notify("Something went wrong",toast.error)
            handleCloseGet(false)
        })
    }

    const submitNewsForm =(e) => {
        e.preventDefault();
        console.log(newLetterMail);
        if(!newLetterMail){
            return notify("email is required",toast.error)
        }
        const response = axios.post('https://api.pumpify.co/subscribe_news_letter', {email:newLetterMail})
        .then((res)=>{
            console.log(res);
            notify("Thanks for subscribe",toast.success)
            setnewLetterMail("")
        })
        .catch((err)=>{
            console.log(err);
            if(err?.response?.data?.message == "Email already subscribed"){
                notify(err?.response?.data?.message,toast.error)
            }else{
                notify("Something went wrong",toast.error)
            }
        })
    }


    return (
        <div className="">
                <Navbar handleShow={handleShow} />
                <div className="container">
                    <div className="row base ai-center">
                        <div className="hp3 col-md-6 ">
                            <div className="mt-5 hp1cont">
                                <div className="hp1txtcont ">
                                    <h1 className="hp1txt" ><span> Digitalize your</span></h1>
                                    <h1 className="hp1txt">Petrol Pump, CNG, LPG, EV Station</h1>
                                    <p className="hp1para">Offering 360 degree solution for you retail station solution. From ordering, accounting to third party accounts offering with whitelabeled app option.</p>
                                    <div className="d-flex">
                                        <button className="themeBtn me-3">how it works</button>
                                        <button className="themeBtnOutline" onClick={()=>handleShowGet("Get a free demo")}>Get a free demo</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hp4 col-md-6 ">
                            <div className="hp2cont">
                                <img src={star} alt="" className="star" />
                                <img src={pumpBanner} className="hp2img" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            {/* <div className="row logolist">
                <div className="row col-8 logocont">
                    <div className="col-md-6 logop1 ">

                        <img data-aos="zoom-in" src={codeomatics} alt="" className="codeomatics" />
                    </div>
                    <div className="col-md-6 logop2 ">
                        <img data-aos="zoom-in" src={dapp} alt="" className="codeomatics" />

                    </div>
                </div>

            </div> */}
            <section className="givYourSec">
                <div className="effect1"></div>
                <div className="container">
                    <div className="row base2">
                        <div className="pg21 col-md-6  order-2 order-md-1">
                            <div data-aos="zoom-in" className="mobcont">
                                <img className="iphoneimg" src={mobiles} alt="" />
                            </div>
                        </div>
                        <div className="pg22 col-md-6 order-1 order-md-2 ">
                            <div className="pg22txt">
                                <h2 className="p2head">Give Your Customers A Greener App</h2>
                                <br />

                                <p className="p2para">Offer your customers a new way to transact at your pump, gas or ev station. Rather than doing the traditional parchi book system which costs you per book printing. It saves filing space and the promotes a greener alternative to local account system. Remove the the parchi, add a whole new dimension to offer the customer an alternative to credit cards.</p>
                                <button className="p2-btn"><h6 className="btn-txt" onClick={()=>handleShowGet("Learn More")}>Learn More &rarr;</h6></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="givYourSec">
                <div className="effect2"></div>

                <div className="container">
                    <div className="row base2">
                        <div className="pg22 col-md-6 ">
                            <div className="pg22txt">
                                <h2 className="p2head">Give Your Customers A Greener App</h2>
                                <br />

                                <p className="p2para">Offer your customers a new way to transact at your pump, gas or ev station. Rather than doing the traditional parchi book system which costs you per book printing. It saves filing space and the promotes a greener alternative to local account system. Remove the the parchi, add a whole new dimension to offer the customer an alternative to credit cards.</p>
                                <button className="p2-btn"><h6 className="btn-txt" onClick={()=>handleShowGet("Learn More")}>Learn More &rarr;</h6></button>
                            </div>
                        </div>

                        <div className="pg21 jc-fe col-md-6 ">
                            <div data-aos="zoom-in" className="mobcont">
                                <img className="iphoneimg" src={mobile2} alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <div className="manageSec">
                <div className="container">
                <h1 className="p2head">Manage Your Pump</h1>
                    <div className="row base3">
                        <div className="col-md-6 uppic">
                            <div data-aos="zoom-in-down" className="imgbox">
                                <img src={laptop} alt="" className="laptop" />
                            </div>
                        </div>
                        <div className="col-md-6 uptxt">
                            <div className="txtcontp2">
                                <p className="uppara">Pumpify is a fuel management solution for ordering(coming soon), accounting and customer management.</p>
                                <div data-aos="zoom-in" className="point1">
                                    <div className="pointtick">
                                        <span></span>
                                    </div>
                                    <div className="pointtxt">
                                        <p className="upitems">Give your customers an and a portal to manage their fueling needs.</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" className="point2">
                                    <div className="pointtick">
                                        <span></span>
                                    </div>
                                    <div className="pointtxt">
                                        <p className="upitems">Let your customers monitor fuelling.</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" className="point3">
                                    <div className="pointtick">
                                        <span></span>
                                    </div>
                                    <div className="pointtxt">
                                        <p className="upitems">Manage your Retail outs local accounting from, reporting, payments, bank reconciliation to daily, monthly and yearly PNL.</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" className="point4">
                                    <div className="pointtick">
                                        <span></span>
                                    </div>
                                    <div className="pointtxt">
                                        <p className="upitems">Maintain your daily ledgers and eliminate the "khata system" .</p>
                                    </div>
                                </div>
                                <br />
                                <button className="themeBtnOutline" onClick={()=>handleShowGet("Learn More")}>Learn More &rarr;</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="manageSec">
                <div className="container">
                    <h1 className="p2head">Schedule Your Post Whenever You Want</h1>
                    <div className="row base3">

                        <div className="col-md-6 uptxt order-2 order-md-1 ">
                            <div className="txtcontp2">
                                <p className="uppara">Pumpify is a fuel management solution for ordering(coming soon), accounting and customer management.</p>
                                <div data-aos="zoom-in" className="point1">
                                    <div className="pointtick">
                                        <span></span>
                                    </div>
                                    <div className="pointtxt">
                                        <p className="upitems">Give your customers an and a portal to manage their fueling needs.</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" className="point2">
                                    <div className="pointtick">
                                        <span></span>
                                    </div>
                                    <div className="pointtxt">
                                        <p className="upitems">Let your customers monitor fuelling.</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" className="point3">
                                    <div className="pointtick">
                                        <span></span>
                                    </div>
                                    <div className="pointtxt">
                                        <p className="upitems">Manage your Retail outs local accounting from, reporting, payments, bank reconciliation to daily, monthly and yearly PNL.</p>
                                    </div>
                                </div>
                                <div data-aos="zoom-in" className="point4">
                                    <div className="pointtick">
                                        <span></span>
                                    </div>
                                    <div className="pointtxt">
                                        <p className="upitems">Maintain your daily ledgers and eliminate the "khata system" .</p>
                                    </div>
                                </div>
                                <br />
                                <button className="themeBtnOutline" onClick={()=>handleShowGet("Learn More")}>Learn More &rarr;</button>
                            </div>
                        </div>
                        <div className="col-md-6 uppic order-1 order-md-2" >
                            <div data-aos="zoom-in-up" className="imgbox">
                                <img src={laptop} alt="" className="laptop" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
            <div className="container">
                <div className="cardsbase">
                    <div className=" ">
                        <h1 className="p2head">Track any hashtag(s) </h1>
                        <p className="cbpara">Don’t waste time on search manual tasks. Let Automation do it for you. Simplify workflows, reduce errors, and save time.</p>

                        <div className="row cardcont">

                            <div data-aos="zoom-in-right" className="col-md-5 col-sm-6 ">
                                <div className="c1">

                                    <div className="c1logo">
                                        <img src={logo1} alt="" />
                                    </div>
                                    <h2 className="cardhead">Hashtag Growth</h2>
                                    <p className="cardpara">Follow a hashtag growth total posts, videos and images.</p>
                                </div>
                            </div>
                            <div data-aos="zoom-in-right" className="col-md-5 col-sm-6 ">
                                <div className="c1">

                                    <div className="c1logo">
                                        <img src={logo2} alt="" />
                                    </div>
                                    <h2 className="cardhead">Influencers by Hashtag</h2>
                                    <p className="cardpara">Identify the most influential people posting with your hashtag.</p>
                                </div>
                            </div>

                            <div data-aos="zoom-in-right" className="col-md-5 col-sm-6 ">
                                <div className="c1">

                                    <div className="c1logo">
                                        <img src={logo3} alt="" />
                                    </div>
                                    <h2 className="cardhead">Most Influental Post</h2>
                                    <p className="cardpara">See the most influential posts on hashtag you
                                    are following on.</p>
                                </div>
                            </div>
                            <div data-aos="zoom-in-right" className="col-md-5 col-sm-6 ">
                                <div className="c1">

                                    <div className="c1logo">
                                        <img src={logo4} alt="" />
                                    </div>
                                    <h2 className="cardhead">Hashtag Location</h2>
                                    <p className="cardpara">Visualize where people are posting using your
                                    hashtag made.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="powerSec">
                <div className="container">
                    <h2 className="heading_base5">Powering the growth of 100+ business & retailers in Indonesia.</h2>
                        <div className="row ">
                            <div className="col-md-4">
                                <PowerCard
                                 desc={"“With Ehya, we’re able to easily track our performance in full detail. It’s become an essential tool for us to grow and engage with our audience.”"}
                                 name={"Rebecca Shambtop"}
                                 desig={"Digital Marketing Executive, Hypebeast"}
                                 image={user}
                                  />
                            </div>
                            <div className="col-md-4">
                                <PowerCard
                                 desc={"“With Ehya, we’re able to easily track our performance in full detail. It’s become an essential tool for us to grow and engage with our audience.”"}
                                 name={"Rebecca Shambtop"}
                                 desig={"Digital Marketing Executive, Hypebeast"}
                                 image={user}
                                  />
                            </div>
                            <div className="col-md-4">
                                <PowerCard
                                 desc={"“With Ehya, we’re able to easily track our performance in full detail. It’s become an essential tool for us to grow and engage with our audience.”"}
                                 name={"Rebecca Shambtop"}
                                 desig={"Digital Marketing Executive, Hypebeast"}
                                 image={user}
                                  />
                            </div>
                        
                        </div>
                </div>
            </div>

            <div className="row gyb mx-0">
                <div className="bluecard">
                    <h1 data-aos="fade-right" className=" ms-4 blu-txt" >Let's build great things together .</h1>
                    <div className="d-flex jc-center" data-aos="zoom-in" >
                        <button className="themeBtn mx-0 mt-4" onClick={()=>handleShowGet("Get in touch")}>Get in touch</button>
                    </div>
                </div>

            </div>
           
            {/* footer start */}
            <div className="footer">
                <div className="container-fluid bg-dark text-secondary mt-5 fMain">
                <div className="row gx-5 mx-xs-0">
                    <div className="col-lg-8 col-md-6">
                    <div className="row gx-5 mx-xs-0">
                        <div className="col-lg-4 col-md-12 pt-5 mb-5">
                        <div className="fLogo mb-4">
                            <img src={pumpifylogo} alt="" className="pumplogo" />
                        </div>
                        <div className="d-flex mb-2">
                            <i className="bi bi-geo-alt text-primary me-2" />
                            <p className="mb-0">Ayra Heights, Gulistan-e-Johar, Block 15, Karachi.</p>
                        </div>
                        <div className="d-flex mb-2">
                            <i className="bi bi-envelope-open text-primary me-2" />
                            <a className="mb-0" href="mailto:contact@pumpify.co">contact@pumpify.co</a>
                        </div>
                        <div className="d-flex mb-2">
                            <i className="bi bi-telephone text-primary me-2" />
                            <a className="mb-0" href="tel:+92 335 2491559">+92 335 2491559</a>
                        </div>
                        <div className="d-flex mt-4">
                            <a className="btn btn-primary btn-square rounded-circle me-2" href="#"><i className="fab fa-twitter" /></a>
                            <a className="btn btn-primary btn-square rounded-circle me-2" href="#"><i className="fab fa-facebook-f" /></a>
                            <a className="btn btn-primary btn-square rounded-circle me-2" href="#"><i className="fab fa-linkedin-in" /></a>
                            <a className="btn btn-primary btn-square rounded-circle" href="#"><i className="fab fa-instagram" /></a>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                        <h4 className="text-uppercase text-light mb-4">Quick Links</h4>
                        <div className="d-flex flex-column justify-content-start">
                            <a className="text-secondry mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2" />Home</a>
                            <a className="text-secondry mb-2" href="#" onClick={()=>handleShowGet("Demo")}><i className="bi bi-arrow-right text-primary me-2" />Demo</a>
                            <a className="text-secondry mb-2" href="https://dashboard.pumpify.co/#/hr"><i className="bi bi-arrow-right text-primary me-2" />HR verification</a>

                        </div>
                        </div>
                        <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                        <h4 className="text-uppercase text-light mb-4">Popular Links</h4>
                        <div className="d-flex flex-column justify-content-start">
                            <a className="text-secondry mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2" />Home</a>
                            <a className="text-secondry mb-2" href="https://dashboard.pumpify.co/#/account"><i className="bi bi-arrow-right text-primary me-2" />Sign Up</a>
                            <a className="text-secondry mb-2" href="https://dashboard.pumpify.co/#/login"><i className="bi bi-arrow-right text-primary me-2" />Login</a>
                            <a className="text-secondry" href="#"><i className="bi bi-arrow-right text-primary me-2" />Contact Us</a>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                    <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 newslMain bg-primary ">
                        <h4 className="text-uppercase text-white mb-4">Newsletter</h4>
                        <h6 className="text-uppercase text-white">Subscribe Our Newsletter</h6>
                        <p className="text-light">Membership/customer deals and promotions</p>
                        <form onSubmit={(e)=>submitNewsForm(e)}>
                        <div className="input-group">
                            <input type="email" onChange={(e)=>setnewLetterMail(e.target.value)} value={newLetterMail} className="form-control border-white p-3" placeholder="Your Email" />
                            <button className="btn btn-dark" >Sign Up</button>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
                <div className="container-fluid py-4 py-lg-0 px-5" style={{background: '#111111'}}>
                <div className="row gx-5">
                    <div className="col-lg-8">
                    <div className="py-lg-4 text-center">
                        <p className="text-secondary mb-0">© <a className="text-light fw-bold" href="#">pumpify</a>. All Rights Reserved.</p>
                    </div>
                    </div>
                    <div className="col-lg-4">
                    <div className="py-lg-4 text-center credit">
                        <p className="text-light mb-0">Develop by <a className="text-light fw-bold" href="https://dappomatics.com/">dappomatics</a></p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {/* footer end */}

            {/* Contact Modal */}
            <Modal show={show} onHide={handleClose} className="contactMM" centered={true}>
                <Modal.Body className='contactModal' >
                    <div className="w-100">
                        <div className="heading">
                        <h1>{connectModalText}</h1>
                        <i class="fa fa-times" onClick={handleClose}></i>
                        </div>
                        <div className="mainContent">
                        <div className="row mx-0">
                            <div className="col-md-6">
                            <div className='mb-5'>
                                <p className="contact">
                                You’ve any question? <br />
                                feel free to contact with us.
                                </p>
                                <div className="socialMain">
                                <a href="https://www.linkedin.com/company/dappomatics" target="_blank" > <i class="fab fa-2x fa-linkedin fb"></i></a>
                                <a href="https://www.facebook.com/dappomatics" target="_blank" > <i class="fab fa-2x fa-facebook-square fb"></i></a>
                                <a href="https://twitter.com/dappomatics" target="_blank" > <i class="fab fa-2x fa-twitter-square twitter"></i></a>
                                <a href="https://www.instagram.com/dappomatics" target="_blank" > <i class="fab fa-2x fa-instagram git"></i></a>
                                </div>
                                <div className='contentDiv'>
                                <div className="imgDiv">
                                    <img src={phone} alt="phone" />
                                </div>
                                <div className='contactDiv'>
                                    <p>Call us for imiditate support on this number</p>
                                    <a href="tel:+ 92 335 2491559"  >+92 335 2491559</a>
                                </div>
                                </div>
                                <div className='contentDiv'>
                                <div className="imgDiv">
                                    {/* <img src={send} alt="phone" /> */}
                                    <i class="fas fa-2x fa-envelope p-0"></i>
                                </div>
                                <div className='contactDiv'>
                                    <p>Send us email for any kind of inquiry</p>
                                    <a href="mailTo:contact@pumpify.com" target="_blank" >contact@pumpify.com</a>
                                </div>
                                </div>
                            
                            </div>
                            </div>
                            <div className="col-md-6">
                            <form onSubmit={(e)=>submitContactForm(e)}>
                            <div className="form-group">
                                {/* <label htmlFor="exampleInputPassword1">Password</label> */}
                                <input onChange={(e)=>handleContactF(e)} name="name" value={contactForm?.name} type="text" className="form-control" id="exampleInputPassword1" placeholder="Name" />
                            </div>
                            <div className="form-group">
                                {/* <label htmlFor="exampleInputEmail1">Email address</label> */}
                                <input onChange={(e)=>handleContactF(e)} name="email" value={contactForm?.email} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Your email address*" />
                            </div>
                        
                            <div className="form-group w-100">
                                <input onChange={(e)=>handleContactF(e)} name="phone" value={contactForm?.phone} type="text" className="form-control" id="exampleInputtext1" placeholder="Phone" />
                            </div>
                            
                            <div className="form-group">
                                <textarea onChange={(e)=>handleContactF(e)} name="message" value={contactForm?.message} type="text" className="form-control" id="exampleInputPassword1" placeholder="Write message here*" rows={4} />
                            </div>
                            <div className="text-center mt-4">
                                <button type="submit" className="btn btn-primary sendBtn">Submit</button>
                            </div>
                            </form>
                            </div>
                        </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* get in touch */}
            <Modal className="demoModal" show={showGetIn} onHide={handleCloseGet} centered={true} >
                <Modal.Body className='contactModal' >
                    <div className="container">
                        <div className="heading text-center">
                        <h1>{getModalText}</h1>
                        <p className="mb-4">Find out more about pumpify.</p>
                        <i class="fa fa-times" onClick={handleCloseGet}></i>
                        </div>
                        <div className="row ai-center">
                            <div className="col-lg-7">
                                <div className="modalImg">
                                    <img className="img-fluid" src={modalImg} alt="pump" />
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="mainContent">
                                <div className="row mx-0">
                                    <div className="col-md-12">
                                    <form onSubmit={(e)=>submitDemoForm(e)}>
                                    <div className="form-group mb-4">
                                        <label htmlFor="Name">Name</label>
                                        <input onChange={(e)=>handleDemoF(e)} name="name" value={demoForm.name} type="text" className="form-control" id="exampleInputPassword1" placeholder="Your Name" />
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="exampleInputEmail1">Email address *</label>
                                        <input onChange={(e)=>handleDemoF(e)} name="email" value={demoForm.email} type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Your email address*" />
                                    </div>
                                    
                                    <div className="form-group mb-4">
                                        <label htmlFor="exampleInputEmail1">Message *</label>
                                        <textarea onChange={(e)=>handleDemoF(e)} name="message" value={demoForm.message} type="text" className="form-control" id="exampleInputPassword1" placeholder="Write message here*" rows={4} />
                                    </div>
                                    <div className="text-center mt-4">
                                        <button type="submit" className="btn btn-primary sendBtn">Submit</button>
                                    </div>
                                    </form>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            
            </Modal>
         
          <ToastContainer pauseOnFocusLoss={false} style={{ width: "max-content" }}/>      

        </div>
    );
}

const PowerCard = ({desc,name,desig,image}) => {
    return(
        <div className="powerCard">
            <div className="icon">
                <img src={quote} alt="" />
            </div>
            <p className="desc">{desc}</p>
            <div className="profile">
                <div className="imgdiv">
                    <img src={image} alt="" />
                </div>
                <div>
                    <div className="name">{name}</div>
                    <div className="desig">{desig}</div>
                </div>
            </div>
        </div>
    )
}

export default Pumpify;




