
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import Pumpify from './landing';
import { BrowserRouter, BrowserRouter as Router,Route,Switch } from "react-router-dom";

import aos from "aos";

import "aos/dist/aos.css"
import { Privacy } from "./page/privacy";
aos.init();


function App() {
  return (
    <>
      <BrowserRouter>
        <Router>
          <Route exact path="/" component={()=><Pumpify /> } />
          <Route exact path="/privacy" component={()=><Privacy /> } />

        </Router>
      </BrowserRouter>


      
    </>
  );
}

export default App;
