import React, { useEffect, useState } from "react";
import pumpifylogo from './images/pumpLogo.svg'
import pumpifylogoDark from './images/2.png'

function Nav({handleShow}) {
    const [showDarkLogo,setShowDarkLogo]= useState(false)

    function navBar() {
                    // when to show scroll top button
    window.addEventListener('scroll', () => {
        const scrollable = document.documentElement.scrollHeight - window.innerHeight; //total no of line
        const scrolled = window.scrollY; //specific no of line where we have ,when scoll it increase
        // console.log(Math.ceil(scrolled))
        if (Math.ceil(scrolled) > 150) {
          
            document.getElementById('downNav').classList.add('downNav');
            document.getElementById('toggleIco').style.filter="unset";
            setShowDarkLogo(true)
        } else {
            document.getElementById('downNav').classList.remove('downNav');
            document.getElementById('toggleIco').style.filter="invert(1)";
            setShowDarkLogo(false)
        }
    });
    }

    useEffect(()=>{
        navBar()
    },[])

    return (
        // downNav
        <nav class="navbar navbar-expand-lg navbar-dark " id="downNav">
            <div class="container">
                <a class="navbar-brand ms-5 text-dark PUMPIFY" href="#">
                    {showDarkLogo?
                    <img src={pumpifylogoDark} alt="" className="pumplogonav" />
                    :
                    <img src={pumpifylogo} alt="" className="pumplogonav" />
                    }
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon" id="toggleIco"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mx-auto ai-center mb-2 mb-lg-0  navcont">
                        <li class="nav-item help">
                            <a href="https://dashboard.pumpify.co/account">Sign Up</a>
                        </li>
                        <li class="nav-item help">
                            {/* <button class="btn btn-outline-primary" type="submit">Login </button> */}
                            <a href="https://dashboard.pumpify.co/login">Login</a>
                        </li>
                        <li class="nav-item help">
                            {/* <button class="btn btn-outline-primary" type="submit">HR verification</button> */}
                            <a href="https://dashboard.pumpify.co/hr">HR verification</a>
                        </li>
                        <li class="nav-item docs">
                            <a class="contactNav dNone-lg" onClick={()=>handleShow("Contact Us")}>Contact Us</a>
                        </li>

                    </ul>
                </div>
                <div className="dFlex-lg">
                    <a class="contactNav " onClick={()=>handleShow("Contact Us")}>Contact Us</a>
                </div>
            </div>
        </nav>
    );
}

export default Nav;